import { createWallet } from "@/api/auth/request";
import { getBalance } from "@/api/ziupay/request";

const state = {
  selectWallet: {},
  transReqForm: {
    reqAmount: 0,
    cvtAmount: 0,
    cvtCurrency: "krw",
    chain: "",
    txId: "",
    walletAddr: "",
    coin: "",
    txType: 0, // 0 deposit, 1 withdrawal
  },
  tronLink: {},
  trc20Balance: 0,
  walletMethod: [],
  walletNetwork: [],
  prices: [],
  price: 0,
  balance: null,
};

const getters = {
  getSelectWallet(state) {
    return state.selectWallet;
  },
  getTransReqForm(state) {
    return state.transReqForm;
  },
  getWalletLink(state) {
    return state.tronLink;
  },
  getTrc20Balance(state) {
    return state.trc20Balance;
  },
  getSelectMethod(state) {
    return state.walletMethod;
  },
  getSelectNetwork(state) {
    return state.walletNetwork;
  },
  getPrices(state) {
    return state.prices;
  },
  getPrice(state) {
    return state.price;
  },
  getBalance(state) {
    return state.balance;
  },
};

const mutations = {
  setTrc20Balance: (state, payload) => {
    state.trc20Balance = payload;
  },
  setSelectWallet: (state, payload) => {
    state.selectWallet = payload;
  },
  setTransReqForm: (state, payload) => {
    state.transReqForm[payload.key] = payload.value;
  },
  setWalletLink: (state, payload) => {
    state.tronLink = payload;
  },
  initSelectWallet: (state) => {
    state.selectWallet = {};
  },
  setSelectMethod: (state, payload) => {
    state.walletMethod = payload;
  },
  setSelectNetwork: (state, payload) => {
    state.walletNetwork = payload;
  },
  setPrices: (state, payload) => {
    state.prices = payload;
  },
  setPrice: (state, payload) => {
    state.price = payload;
  },
  setBalance: (state, payload) => {
    state.balance = payload;
  },

  handleSelectWallet(state, payload) {
    sessionStorage.setItem("wallet_address", payload.address);
    state.selectWallet = payload;
  },
};

const actions = {
  async getBalanceAction({ commit }, data) {
    const res = await getBalance(data);
    if (res.code == 0) {
      commit("setBalance", res.data);
    }
  },
  async createWalletAction({ commit }, data) {
    const res = await createWallet(data);
    console.log(res);
    return res;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
