import {
	getPaymentHistoryRequest,
	getUncapprovedPaymentHistoryRequest,
	updatePaymentHistory,
	updatePaymentHistoryRequest,
	getListPaymentRequest,
} from "@/api/payment/request";

import moment from "moment";

const state = {
	paymentHistory: {},
	unapprovedPaymentHistory: {},
	unapprovedPaymentHistory2: {},
};

const getters = {};

const mutations = {
	handleUpdateListPaymentHistory(state, payload) {
		state.paymentHistory = payload;
	},

	handleUpdateListUnapprovedPaymentHistory(state, dispatch) {
		state.unapprovedPaymentHistory = dispatch;
	},

	handleUpdateListUnapprovedPaymentHistory2(state, dispatch) {
		state.unapprovedPaymentHistory2 = dispatch;
	},
};

const actions = {
	async getPaymentHistoryAction({ commit }, data) {
		const res = await getListPaymentRequest(data);
		if (res?.data?.status == 0) {
			commit("handleUpdateListPaymentHistory", {
				list: res.data.data,
				pagination: {
					limit: res.data.limit,
					page: res.data.page,
					total: res.data.total,
				},
			});
		}
	},

	async getUnapprovedPaymentHistoryAction({ commit }, data) {
		const res = await getListPaymentRequest(data);
		if (res.data.status == 0) {
			commit("handleUpdateListUnapprovedPaymentHistory", {
				list: res.data.data,
				pagination: {
					limit: res.data.limit,
					page: res.data.page,
					total: res.data.total,
				},
			});
		}
		commit("handleUpdateLoading", false);
	},

	async getUnapprovedPaymentHistoryAction2({ commit }, data) {
		const res = await getListPaymentRequest(data);
		if (res.data.status == 0) {
			commit("handleUpdateListUnapprovedPaymentHistory2", {
				list: res.data.data,
				pagination: {
					limit: res.data.limit,
					page: res.data.page,
					total: res.data.total,
				},
			});
		}
		commit("handleUpdateLoading", false);
	},

	async handleUpdatePaymentHistory({ commit }, data) {
		const res = await updatePaymentHistory(data);
		return res.data;
	},
};

export default { state, getters, mutations, actions };
