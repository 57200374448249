import { createI18n } from "vue-i18n";

let firstKey = process.env.VUE_APP_LANGUAGE != null ? process.env.VUE_APP_LANGUAGE : 'ko';

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : firstKey,
  messages: {
    en: require("./en.json"),
    ko: require("./ko.json"),
    th: require("./th.json"),
    ch: require("./ch.json"),
    jp: require("./jp.json"),
    mn: require("./mn.json"),
  },
});

export default i18n;
