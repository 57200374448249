import {
	deleteWithDrawRequest,
	updateWithdrawHistory,
} from "@/api/withdrawPayment/request";
import { convertMoney } from "../../utils";
import store from "@/store";
import i18n from "@/locales/index";
import { v4 as uuidv4 } from "uuid";
import { getListPaymentRequest, getPaymentDetail } from "@/api/payment/request";
import moment from "moment";

function randomIntFromInterval() {
	return Math.ceil(Math.random() * (300 - 10) + 10) * 1000;
}

function getRandomTimeInDay() {
	var today = new Date();
	var randomHour = Math.floor(Math.random() * (today.getHours() + 1));
	var randomMinute = Math.floor(Math.random() * 60);
	today.setHours(randomHour, randomMinute, 0, 0);
	return today;
}

const state = {
	listWithDraw: null,
	listUnapprovedWithDraw: null,
	listUnapprovedWithDraw2: null,
	listWithDrawRealTime: [],
	check: false,
	detail: {},
};

const getters = {
	getListWithDrawRealTime(state) {
		while (state.listWithDrawRealTime.length < 35) {
			let currentTime = new Date();
			let randomTime = getRandomTimeInDay();
			randomTime = randomTime > currentTime ? currentTime : randomTime;

			state.listWithDrawRealTime.push({
				wi_member_nickname: `*****${uuidv4().slice(-2)}`,
				wi_created: randomTime,
				wi_money: convertMoney(randomIntFromInterval()),
			});
		}
		if (state.listWithDrawRealTime.length % 2 === 0) {
			let currentTime = new Date();
			let randomTime = getRandomTimeInDay();
			randomTime = randomTime > currentTime ? currentTime : randomTime;

			state.listWithDrawRealTime.push({
				wi_member_nickname: `*****${uuidv4().slice(-2)}`,
				wi_created: randomTime,
				wi_money: convertMoney(randomIntFromInterval()),
			});
		}

		return state.listWithDrawRealTime.sort(
			(a, b) => b.wi_created - a.wi_created
		);
	},
	totalAmountWithDraw(state) {
		let total = 0;
		state.listWithDraw?.forEach((e) => (total = total + e.wi_money));
		return total;
	},
};

const mutations = {
	// handleUpdateListWithDraw(state, payload) {
	//   if (state.listWithDraw != null && state.listWithDraw?.length) {
	//     if (
	//       state.listWithDraw[0].wi_idx === payload[0]?.wi_idx &&
	//       state.listWithDraw[0].wi_status !== payload[0]?.wi_status
	//     ) {
	//       payload[0]?.wi_status === "COMPLETED"
	//         ? store.dispatch("showAlert", {
	//             type: "success",
	//             message: i18n.global.t("withdrawRequestApproved"), //WITHDRAW REQUEST Approved
	//           })
	//         : store.dispatch("showAlert", {
	//             type: "error",
	//             message: i18n.global.t("withdrawRequestCanceled"), //WITHDRAW REQUEST CANCELED
	//           });

	//       state.check = true;
	//     }
	//   }
	//   state.listWithDraw = [...payload];
	// },
	handleUpdateListWithDrawRealTime(state, payload) {
		// state.listWithDrawRealTime = [...payload];
		// state.listWithDrawRealTime = state.listWithDrawRealTime?.filter(
		//   (item) =>
		//     new Date(item?.wi_created).getFullYear() ==
		//       new Date().getUTCFullYear() &&
		//     new Date(item?.wi_created).getMonth() == new Date().getUTCMonth() &&
		//     new Date(item?.wi_created).getDate() == new Date().getUTCDate()
		// );
	},
	handleWithDrawCheck(state) {
		state.check = false;
	},
	handleUpdateListWithdrawHistory(state, payload) {
		state.listWithDraw = payload;
	},
	handleUpdateListUnapprovedWithdrawHistory(state, payload) {
		state.listUnapprovedWithDraw = payload;
	},
	handleUpdateListUnapprovedWithdrawHistory2(state, payload) {
		state.listUnapprovedWithDraw2 = payload;
	},
	clearWithDraw(state) {
		state.listWithDraw = null;
		state.check = false;
	},
	handleUpdateWithdrawDetail(state, payload) {
		state.detail = payload;
	},
};

const actions = {
	// async getListWithDrawAction({ commit }, data) {
	//   const res = await getListWithDrawRequest(data);
	//   if (res?.is_success) {
	//     commit("handleUpdateListWithDraw", res?.list);
	//   } else {
	//     /*alert(res?.message)*/
	//     commit("handleUpdateListWithDraw", []);
	//   }
	// },
	// async withdrawAction({ commit }, data) {
	//   const res = await withdrawRequest(data);
	//   return res;
	// },
	// async withdrawListRealTimeAction({ commit }) {
	//   const res = await withdrawListRealTime();
	//   if (res?.is_success) {
	//     commit("handleUpdateListWithDrawRealTime", res.list);
	//   }
	//   // else {
	//   //    console.log(res?.message);
	//   // }
	// },
	async deleteWithdrawAction({ dispatch }, { idx, data }) {
		const res = await deleteWithDrawRequest(idx, data);
		if (res?.is_success) {
			dispatch("getListWithDrawAction", data);
		}
		// else {
		//    console.log(res?.message);
		// }
		return res;
	},

	// async getWithdrawHistoryAction({ commit }, data) {
	//   const res = await getListWithDrawRequest(data);
	//   let sp = new URLSearchParams(location.href);
	//   let filtered = [];
	//   if (res.data.code == 0) {
	//     res.data.wData.forEach((v) => {
	//       Object.values(v).forEach((vv) => {
	//         vv = JSON.parse(vv);
	//         filtered.push({
	//           store_id: vv.storeCode,
	//           user_id: vv.userIdx,
	//           withdraw_name: vv.coin,
	//           allow_status: vv?.status ?? 1,
	//           credit_amount: vv.reqAmount,
	//           reg_date: moment(vv.createTime).format("YYYY-MM-DD HH:mm:ss"),
	//           raw: vv,
	//         });
	//       });
	//     });

	//     commit("handleUpdateListWithdrawHistory", {
	//       list: filtered.sort(
	//         (a, b) =>
	//           moment(b.createTime).valueOf() - moment(a.createTime).valueOf()
	//       ),
	//       pagination: {
	//         limit: res.data.limit,
	//         page: res.data.page,
	//         total: filtered.lengt,
	//       },
	//     });
	//     commit("handleUpdateLoading", false);
	//   }
	// },

	async getWithdrawHistoryAction({ commit }, data) {
		const res = await getListPaymentRequest(data);

		try {
			if (res.data.status == 0) {
				commit("handleUpdateListWithdrawHistory", {
					list: res.data.data,
					pagination: {
						limit: res.data.limit,
						page: res.data.page,
						total: res.data.total,
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
	},

	async getUnapprovedWithdrawHistoryAction({ commit }, data) {
		const res = await getListPaymentRequest(data);
		try {
			if (res.data.status == 0) {
				commit("handleUpdateListUnapprovedWithdrawHistory", {
					list: res.data.data,
					pagination: {
						limit: res.data.limit,
						page: res.data.page,
						total: res.data.total,
					},
				});
			}
			commit("handleUpdateLoading", false);
		} catch (error) {
			console.log(error);
			commit("handleUpdateLoading", false);
		} finally {
			commit("handleUpdateLoading", false);
		}
	},

	async getUnapprovedWithdrawHistoryAction2({ commit }, data) {
		const res = await getListPaymentRequest(data);
		try {
			if (res.data.status == 0) {
				commit("handleUpdateListUnapprovedWithdrawHistory2", {
					list: res.data.data,
					pagination: {
						limit: res.data.limit,
						page: res.data.page,
						total: res.data.total,
					},
				});
			}
			commit("handleUpdateLoading", false);
		} catch (error) {
			console.log(error);
			commit("handleUpdateLoading", false);
		} finally {
			commit("handleUpdateLoading", false);
		}
	},

	async getDetailWithdraw({ commit }, data) {
		const res = await getPaymentDetail(data);
		try {
			if (res.data.status == 0) {
				commit(
					"handleUpdateWithdrawDetail",
					JSON.parse(res.data.data.raw_json)
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			commit("handleUpdateLoading", false);
		}
	},

	// async getUnapprovedWithdrawHistoryAction({ commit }, data) {
	//   const res = await getMetaRequest(data);
	//   try {
	//     if (res.data.status == 0) {
	//       let filtered = res.data.data.filter(
	//         (i) =>
	//           i.route_name == "payment/unapproved_withdrawal_history" &&
	//           i.active_status == 1
	//       );

	//       filtered.sort(
	//         (a, b) => moment(b.reg_date).valueOf() - moment(a.reg_date).valueOf()
	//       );

	//       commit(
	//         "handleUpdateListUnapprovedWithdrawHistory",
	//         filtered.sort(
	//           (a, b) =>
	//             moment(b.reg_date).valueOf() - moment(a.reg_date).valueOf()
	//         )
	//       );
	//     }
	//   } catch (error) {
	//     console.log(error);
	//   } finally {
	//     commit("handleUpdateLoading", false);
	//   }
	// },

	async handleUpdateWithdrawHistory({ commit }, data) {
		const res = await updateWithdrawHistory(data);
		return res.data;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
