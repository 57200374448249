import { getCustomPage } from "@/api/customPage/request";

const state = {
  styles: {
    bg_color: "",
    bg_image: "",
    chat_yn: "0",
    footer_banners: null,
    form_pos: "",
    logo_image: null,
    logo_pos: "",
    nav_pos: null,
    template: "",
    game_vendors: null,
    links: "",
    front_title: "",
    is_load: false,
  },
};

const getters = {};

const mutations = {
  handleUpdateStyles(state, payload) {
    state.styles = payload;
    state.styles.is_load = true;
  },
};

const actions = {
  async getStylesAction({ commit }) {
    const res = await getCustomPage();
    try {
      commit("handleUpdateStyles", res?.data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
