import { SSRRequest, AuthRequest } from "@/api/axios";
import { BASE_API_URL2 } from "@/const/index";
import queryString from "query-string";

export const getListWithDrawRequest = async (params) => {
	let data =   { all: true };
	const res = await AuthRequest({
		baseURL: BASE_API_URL2,
		url: `/transaction/list${
			params ? `?${queryString.stringify(params)}` : ""
		}`,
		headers: {
			'Content-Type': 'application/json'
		},
		method: "POST",
		data
	});
	return res;
};

export const getListUnapprovedWithDrawRequest = async (params) => {
	const res = await AuthRequest({
		url: `/api/withdraw-history/status/out-completed${
			params ? `?${queryString.stringify(params)}` : ""
		}`,
		method: "GET",
	});
	return res;
};

// export const getListWithDrawRequest = async data => {
//     try{
//         const res = await SSRRequest({
//             url: '/goods/withdraw_list/get',
//             method: 'POST',
//             data
//         })
//         return res
//     }
//     catch (error) {
//         console.log(error)
//     }
// }

export const withdrawRequest = async (data) => {
	const res = await SSRRequest({
		url: "/withdraw/request",
		method: "POST",
		data,
	});
	return res;
};

export const withdrawListRealTime = async () => {
	try {
		const res = await SSRRequest({
			url: "/real_message/withdraws/list",
			method: "POST",
		});
		return res;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const deleteWithDrawRequest = async (idx, data) => {
	const res = await SSRRequest({
		url: `/withdraw/${idx}/delete`,
		method: "POST",
		data,
	});
	return res;
};

export const updateWithdrawHistory = async (data) => {
	let idx = data?.idx;
	delete data["idx"];
	const res = await AuthRequest({
		url: `/api/withdraw-history/${idx}`,
		method: "PUT",
		data,
	});
	return res;
};
