import { SSRRequest, AuthRequest } from "@/api/axios";
import {BASE_API_URL2} from "@/const";

export const loginRequest = async (data) => {
  const res = await SSRRequest({
    url: "/api/auth/login",
    method: "POST",
    data,
  });
  return res;
};

export const signUpRequest = async (data) => {
  const res = await SSRRequest({
    url: "/authentication/ooo_browser/join",
    method: "POST",
    data,
  });
  return res;
};

export const logoutRequest = async (data) => {
  const res = await SSRRequest({
    url: "/authentication/ooo_browser/logout",
    method: "POST",
    data,
  });
  return res;
};

export const createWallet = async (data) => {
  const res = await SSRRequest({
    url: "/api/users_front",
    method: "POST",
    data,
  });

  return res;
};

export const getMeAuthRequest = async () => {
  const res = await AuthRequest({
    url: "/api/auth/me",
    method: "GET",
  });
  return res;
};

export const depositRequest = async (data) => {
  const res = await SSRRequest({
    baseURL: BASE_API_URL2,
    url: "/transaction/deposit/request",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const withdrawRequest = async (data) => {
  const res = await SSRRequest({
    baseURL: BASE_API_URL2,
    url: "/transaction/withdraw/request",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

