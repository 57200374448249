const state = {
  toggleShow: {
    registerModal: false,
    walletModal: false,
    loginModal: false,
  },
  confirmDialog: null,
  isShowModal: false,
  isShowModalGame: false,
  tabDeposit: null,
};

const getters = {};

const mutations = {
  handleGetTabModal(state, payload) {
    state.tabDeposit = payload;
  },

  handleToggle(state, payload) {
    state.toggleShow[payload] = !state.toggleShow[payload];

    if (state.toggleShow.point == false) {
      state.tabDeposit = null;
    }

    const check = Object.values(state.toggleShow).filter((e) => e);
    if (check.length === 0) {
      document.querySelector("html").style.overflowY = "scroll";
    } else {
      document.querySelector("html").style.overflowY = "hidden";
    }
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
