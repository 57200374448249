import {
    getListCouponRequest,
    useCouponRequest
} from "@/api/coupon/request";
// import store from "@/store";
import i18n from "@/locales/index";

const state = {
    listCoupon: [],
};

const getters = {
    listNormalCoupon: (state) =>
        state.listCoupon?.filter((item) => item?.mc_status === "NORMAL"),
    listUsedCoupon: (state) =>
        state.listCoupon?.filter((item) => item?.mc_status === "USED"),
    listCantUsedCoupon: (state) =>
        state.listCoupon?.filter((item) => item?.mc_status === "CANCEL"),
    countCoupon(state) {
        return state.listCoupon.length;
    },
    countNewCoupon(state) {
        let timeNow = Date.now() / 1000;
        let newList = state.listCoupon?.filter((item) => item?.mc_status === "NORMAL" && item?.mc_end_date > timeNow );
        return newList.length;
    },
};

const mutations = {
    handleUpdateCoupon(state, payload) {
        state.listCoupon = payload;
    },
    clearCoupon(state) {
        state.listCoupon = null;
    },
};

const actions = {
    async getlistCouponAction({ commit }, data) {
        const res = await getListCouponRequest(data);
        if (res?.is_success) {
            commit("handleUpdateCoupon", res?.list);
        } else {
            //console.log(res?.message);
            commit("handleUpdateCoupon", []);
        }
    },
    async useCouponAction({ dispatch }, data) {
        const res = await useCouponRequest(data);
        if (res?.is_success) {
            dispatch("showAlert", {
                type: "success",
                message: i18n.global.t("coupon_success"), //MESSAGE CHECK
            });
        } else {
            switch (res?.ErrorCode) {
                case -200001:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_200001"), //MESSAGE CHECK
                    });
                    break;
                case -200002:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_200002"), //MESSAGE CHECK
                    });
                    break;
                case -200003:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_200003"), //MESSAGE CHECK
                    });
                    break;
                case -200004:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_200004"), //MESSAGE CHECK
                    });
                    break;
                case -200005:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_200005"), //MESSAGE CHECK
                    });
                    break;
                default:
                    dispatch("showAlert", {
                        type: "error",
                        message: i18n.global.t("coupon_error_default"), //MESSAGE CHECK
                    });
                    break;
            }

        }
        return res
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
