import { AuthRequest } from "@/api/axios";
import queryString from "query-string";
import store from "@/store";
import { BASE_API_URL2 } from "@/const/index";

/* https://broker.ziupay.com:2083/transaction/list */
export const getPaymentHistoryRequest = async (params) => {
	let data = {
		all: true,
	};
	const res = await AuthRequest({
		baseURL: BASE_API_URL2,
		url: `/transaction/list${
			params ? `?${queryString.stringify(params)}` : ""
		}`,
		headers: {
			"Content-Type": "application/json",
		},
		method: "POST",
		data,
	});
	return res;
};

export const getUncapprovedPaymentHistoryRequest = async (params) => {
	const res = await AuthRequest({
		url: `/api/payment-history/status/out-completed${
			params ? `?${queryString.stringify(params)}` : ""
		}`,
		method: "GET",
	});
	return res;
};

// export const updatePaymentHistory = async (data) => {
//   let idx = data?.idx;
//   delete data["idx"];
//   const res = await AuthRequest({
//     url: `/api/payment-history/${idx}`,
//     method: "PUT",
//     data,
//   });
//   return res;
// };

export const updatePaymentHistory = async (data) => {
	const res = await AuthRequest({
		baseURL: BASE_API_URL2,
		url: '/transaction/deposit/update',
		headers: {
			"Content-Type": "application/json",
		},
		method: "POST",
		data,
	});
	return res;
};

export const getListPaymentRequest = async (params) => {
	const res = await AuthRequest({
		url: `/api/payment-history${
			params ? `?${queryString.stringify(params)}` : ""
		}`,
		method: "GET",
	});

	return res;
};

export const getPaymentDetail = async (id) => {
	const res = await AuthRequest({
		url: `/api/payment-history/${id}`,
		method: "GET",
	});

	return res;
};

export const updateMemoRequestPayment = async (data) => {
	let idx = data.idx;
	delete data["idx"];
	const res = await AuthRequest({
		url: `/api/payment-history/${idx}`,
		method: "PUT",
		data,
	});
	return res;
};
