import { getNotifyMessageRequest } from "@/api/notifyMessage/request";
import { getCookie } from "@/utils/cookie";
import moment from "moment";

const state = {
  list: null,
  listPopup: null,
  idxActiveContent: null,
  activeTab: 0,
};

const getters = {
  getNotice: (state) => {
    const listNotice = state.list?.filter(
      (item) => item?.no_type === "NOTICE_SITE"
    );

    return listNotice?.sort((a, b) => b.no_created - a.no_created);
  },
  getFAQ: (state) =>
    state.list?.filter((item) => item?.no_type === "NOTICE_FAQ"),
  getEvent: (state) =>
    state.list?.filter((item) => item?.no_type === "NOTICE_EVENT"),
  getPopup: (state) =>
    state.list?.filter((item) => item?.no_type === "NOTICE_POPUP"),
  lengthPopup: function (state) {
    let data = state.list?.filter((item) => item?.no_type === "NOTICE_POPUP");
    const dataPopup = data
      ?.slice(0, 2)
      .filter(
        (item) =>
          item?.no_type === "NOTICE_POPUP" && item?.no_status === "ACTIVE"
      );

    if (dataPopup && dataPopup.length > 0) {
      const dataCookie = getCookie("MDS");
      // check cookies if there are any popups to show by day
      if (dataCookie) {
        let count = 0;
        dataPopup.forEach((e) => {
          const check = dataCookie.find((val) => val.no_idx === e.no_idx);
          if (check) {
            return new Date() > check.dataCreate && count++;
          } else {
            count++;
          }
        });
        return count;
      } else {
        return dataPopup.length;
      }
    } else {
      return 0;
    }
  },
};

const mutations = {
  handleUpdateListNotice(state, payload) {
    state.list = [...payload];
  },
  handleActiveContent(state, payload) {
    state.idxActiveContent = payload;
  },
  handleActiveTab(state, payload) {
    state.activeTab = payload;
  },
  handleUpdateNoticeListPopUp(state, payload) {
    state.listPopup = payload;
  },
};

const actions = {
  async getPartnershipAction({ commit }) {
    const res = await getNotifyMessageRequest();
    if (res?.is_success) {
      commit("handleUpdateListNotice", res?.list);
      const listPopup = res?.list.filter(
        (item) => item?.no_type === "NOTICE_POPUP"
      );
      commit("handleUpdateNoticeListPopUp", listPopup);
    } else {
      console.log(res?.message);
      commit("handleUpdateListNotice", []);
    }
  },
  // handleUpdateNoticeListPopUpAction: ({ commit }, newData) => {
  //   commit("handleUpdateNoticeListPopUp", newData);
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
