import { names } from "./datas";
import moment from "moment";

export const convertMoney = (money) => {
  return parseInt(money ?? 0).toLocaleString("en");
};
export const generateRandomName = () => {
  const randomIndex = Math.floor(Math.random() * names.length);
  return names[randomIndex];
};

export const generateRandomNumber = () => {
  const min = 10000;
  const max = 1000000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertTimeYYMMDDV2 = (dateString) => {
  return moment(dateString).locale("vi").format("YY.MM.DD LTS");
};

export const convertTimeYYMMDD = (timestamp) => {
  return `${moment(timestamp * 1000).format("YY.MM.DD LTS")}`;
};

export const formatDateV2 = (timestamp) => {
  return `${moment(timestamp).format("YY.MM.DD")}`;
};

export const formatTimeV2 = (timestamp) => {
  return `${moment(timestamp).format("HH:mm")}`;
};

export const formatDateV1 = (dateString) => {
  return moment(dateString).format("YY.MM.DD");
};

export const formatTime = (dateString) => {
  return moment(dateString * 1000).format("HH:mm");
};

export const formatDate = (dateString) => {
  return moment(dateString * 1000).format("YY.MM.DD");
};

export const formatTimeKr = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = formatTime(timeStamp / 1000 + 32400);
  return timeKr;
};

export const formatDateKr = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = formatDate(timeStamp / 1000 + 32400);
  return timeKr;
};

export const converTime = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  // const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  if (type) {
    return `${moment(timestamp * 1000).format("YY.MM.DD LTS")}`;
  } else {
    return `${moment(timestamp * 1000).format(
      "YY.MM.DD LTS"
    )} ${formattedTime}`;
  }
};

export const convertKrTime = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = convertTimeYYMMDD(timeStamp / 1000 + 32400);
  return timeKr;
};
