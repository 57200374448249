import { getBankInfoRequest } from "@/api/getBankInfo/request";
// import store from "@/store";
// import { computed } from "vue";

const state = {
    listBankInfo: [],
};

const getters = {
    getBankInfo: (state) => {
        return state.listBankInfo;
    },
};

const mutations = {
    handleUpdateBankInfo(state, payload) {
        state.listBankInfo = payload;
    },
};

const actions = {
    async getBankInfoAction({ commit }, data) {
        const res = await getBankInfoRequest();
        if (res?.data) {
            commit("handleUpdateBankInfo", res.data);
        } else {
            console.log(res?.message);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
