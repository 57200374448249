import moment from "moment";
import store from "@/store";
import { deleteCookie } from "./cookie";

export const formatDate = (dateString) => {
  return moment(dateString, "DD.MM.YYYY").format("YY.MM.DD");
};
export const convertMoney = (money) => {
  return parseInt(money).toLocaleString("en");
};

export const convertTime = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  if (type) {
    return `${moment(timestamp * 1000).format("YY.MM.DD hh:mm:ss A")}`;
  } else {
    return `${formattedDate} ${formattedTime}`;
  }
};

export const handleLogout = () => {
  deleteCookie("auth");
  localStorage.clear();
  sessionStorage.clear();
  store.commit("handleAuth");
};
