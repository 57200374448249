import { SSRRequest } from "@/api/axios";
import { BASE_API_URL, BASE_API_URL2 } from "@/const/index";
import store from "@/store";
import queryString from "query-string";
import i18n from "@/locales/index";

const beforeHook = (data) => {
  let token = sessionStorage.getItem("token");
  let idx = sessionStorage.getItem("idx");
  let storeCode = sessionStorage.getItem("storeCode");

  if (token) {
    data.t = token;
  }
  if (idx) {
    data.userIdx = idx;
  }
  if (storeCode) {
    data.storeCode = storeCode;
  }
};

const afterHook = (res) => {
  store.dispatch("showAlert", {
    type: res?.code == 0 ? "success" : "error",
    message: i18n.global.t(res.msg),
  });
};

const zpApi = {
  getTokenUser: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "/auth/get/tokenUser",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    afterHook(res);
    return res;
  },
  getWallet: async (params) => {
    const res = await SSRRequest({
      baseURL: BASE_API_URL2,
      url: `/api/meta-code?all=true&type_search=code_depth1&text_search=${params}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
  confirm: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "transaction/confirm",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    afterHook(res);
    return res;
  },
  refresh: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "transaction/refresh",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    return res;
  },
  history: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "transaction/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    return res;
  },
  price: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL2,
      url: "api/coins",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });

    let idx = store.getters.getUsdtNetwork;
    let network = store.getters.getSelectNetwork[idx];
    let price = 0;
    if (res?.data?.length > 0) {
      let obj = res?.data.find(
        (v) => network?.name?.toUpperCase() == v.network
      );
      price = (obj.price * obj.weight) / 100 + obj.price;
      store.commit("setPrices", price);
    }
    return {
      data: {
        krw: price,
      },
    };
  },
  createTicket: async (data) => {
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "transaction/create/ticket",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    afterHook(res);
    return res;
  },
  balance: async (data) => {
    if (!data?.walletAddr) {
      return;
    }
    beforeHook(data);
    const res = await SSRRequest({
      baseURL: BASE_API_URL,
      url: "transaction/check/balance",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    return res;
  },

  prices: async (data) => {
    const res = await SSRRequest({
      baseURL: BASE_API_URL2,
      url: `/api/coins${data ? `?${queryString.stringify(data)}` : ""}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export const getBalance = async (data) => {
  const res = await SSRRequest({
    baseURL: BASE_API_URL2,
    url: "/transaction/check/balance",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export { zpApi };
